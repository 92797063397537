import React from 'react';
import {motion} from "framer-motion"
import ReactTyped from "react-typed";
import i18next from 'i18next';


const ResellingMain = () => {
    return(
        <div className='flex flex-col background-reseller w-full items-center pt-24 pb-24 justify-center md:justify-center gap-6'>
            <div className='flex flex-col justify-center items-center text-center w-full gap-6'>
                    <h1 className='flex text-gray-50 items-center justify-center md:text-[45px] flex-col text-[35px] text-center w-full font-bold '>
                        {i18next.t('reselling.main.title')} <ReactTyped strings={[i18next.t('reselling.main.description'), i18next.t('reselling.main.description2'), i18next.t('reselling.main.description3')]} loop className='w-full' backSpeed={70} typeSpeed={90}/>{i18next.t('reselling.main.description_end')}
                    </h1>
                    <motion.p animate={{y: [25, 0]}}
                              transition={{ease: "easeIn", duration: 0.4}}
                              className='font-bold h-[366] pl-8 pr-8 md:pr-0 md:pl-0 md:h-full text-gray-50 text-center w-full'>{i18next.t('reselling.main.sub_text')}
                    </motion.p>
                </div>

        </div>
    )
}

export default ResellingMain;