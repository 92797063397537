import React, {useEffect, useState} from "react";
import CanvasComponent from "./canvas";
import {motion} from "framer-motion";

const PortfolioCard = ({ showCard, category, ImageHref, title, button, buttonHref, text }) => {
    const [isCanvasVisible, setIsCanvasVisible] = useState(false);
    const [isAnimating, setIsAnimating] = useState(true);


    const startAnimation = () => {
        setIsAnimating(true);
    };

    const stopAnimation = () => {
        setIsAnimating(false);
    };

    const openCanvas = () => {
        setIsCanvasVisible(true);
    };

    const closeCanvas = () => {
        setIsCanvasVisible(false);
    };

    useEffect(() => {
        if(isCanvasVisible){
            document.body.style.overflow = 'hidden'
        }else{
            document.body.style.overflow = 'auto'
        }
    }, [isCanvasVisible])

    return (
        <>
            <motion.div
                animate={{opacity: isAnimating ? 1 : 0 }}
                initial={{opacity: 0 }}
                transition={{duration: 1 }}
                className={`w-full px-4 md:w-1/2 xl:w-1/3 ${
                    showCard === "all" || showCard === category.toLowerCase()
                        ? "block"
                        : "hidden"
                }`}
            >
                <div className="relative mb-12">
                    <div className="overflow-hidden rounded-[10px]">
                        <img src={ImageHref} alt="portfolio" className="w-full" />
                    </div>
                    <div className="relative mx-7 -mt-20 rounded-lg bg-white dark:bg-dark-2 py-[34px] px-3 text-center shadow-portfolio dark:shadow-box-dark">
            <span className="text-primary mb-2 block text-sm font-medium">
              {category}
            </span>
                        <h3 className="text-dark mb-5 text-xl font-bold">{title}</h3>
                        <button
                            onClick={openCanvas}
                            className="custom-button"
                        >
                            {button}
                        </button>
                        {isCanvasVisible && (
                            <CanvasComponent isVisible={true} closeCanvas={closeCanvas} text={text}>


                            </CanvasComponent>
                        )}
                    </div>
                </div>
            </motion.div>
        </>
    );
};

// Exportieren Sie PortfolioCard als Standardexport
export default PortfolioCard;
