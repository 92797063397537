import React, {useState, useEffect} from 'react';
import {motion} from "framer-motion";
import Swal from 'sweetalert2';
import registerDropdownTel from "./functions/registerDropdownTel";
import registerDropdownCountry from "./functions/registerDropdownCountry";
import {Link} from "react-router-dom";
import '../../index.css'
import i18n from "i18next";
import i18next from "i18next";

const RegisterMain = () => {



    const genderOptions = [
        { key: i18next.t('register.registerMain.male'), value: 'male' },
        { key: i18next.t('register.registerMain.female'), value: 'female' }
    ];

    const [selectedGender, setSelectedGender] = useState('male'); // Geschlecht
    const [selectedCountry, setSelectedCountry] = useState('Deutschland');
    const [selectedTel, setSelectedTel] = useState('+49');

    const [selectedOption, setSelectedOption] = useState('');
    const [isCompanyNameRequired, setIsCompanyNameRequired] = useState(false);
    const handleGenderChange = (event) => {
        setSelectedGender(event.target.value);
    };



    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
        // Hier können Sie die Bedingung festlegen, wann das Feld "companyName" erforderlich ist
        setIsCompanyNameRequired(event.target.value === '3');
    };



    const [formData, setFormData] = useState({

    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        // Diese Funktion wird beim Laden der Seite aufgerufen
        // Setze die Anfangswerte für customertype, gender, country und callCountry
        setSelectedOption('natural');
        setSelectedGender('male');
        setSelectedCountry('Deutschland');
        setSelectedTel('+49');

        // Aktualisiere das formData-Objekt mit den Anfangswerten
        setFormData((prevData) => ({
            ...prevData,
            customertype: 'natural',
            gender: 'male',
            country: 'Deutschland',
            callCountry: '+49',
            lang: i18n.language
        }));
    }, []);

    const handleRegistration = async (event) => {
        handleInputChange(event)
        event.preventDefault();

        // Zeigt die Ladeanimation an
        Swal.fire({
            title: i18next.t('register.registerMain.registerrunning'),
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            }
        });



        if(formData.email !== formData.emailrepeat){
            Swal.fire({
                icon: 'error',
                title: i18next.t('register.registerMain.error'),
                text: i18next.t('register.registerMain.incorrectmail')
            });
        } else {
            try {
                const response = await fetch('https://api.cordes-hosting.net/website/api/register', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                // Schließt die Ladeanimation
                Swal.close();

                if (response.status === 200) {
                    Swal.fire({
                        title: i18next.t('register.registerMain.registersuccess'),
                        text: i18next.t('register.registerMain.registersuccess_text'),
                        icon: 'success',
                        timer: 4000,  // 4 Sekunden Timer
                        timerProgressBar: true, // Zeige den Fortschritt des Timers
                        showCancelButton: false, // Deaktiviere den Abbrechen-Button
                        showConfirmButton: false, // Deaktiviere den OK-Button
                        allowOutsideClick: false, // Deaktiviere das Schließen durch Klicken außerhalb des Alerts
                        willClose: () => {
                            // Hier kommt die Weiterleitungsfunktion. Zum Beispiel:
                            window.location.href = `https://dashboard.cordes-hosting.net/`;
                        }
                    });
                } else if (response.status === 409) {
                    Swal.fire(i18next.t('register.registerMain.emailalreadyexist'), '', 'error');
                } else {
                    Swal.fire(i18next.t('register.registerMain.error_text'), '', 'error');
                }
            } catch (error) {
                // Schließt die Ladeanimation im Fehlerfall
                Swal.close();
                console.error('Error:', error);
                Swal.fire(i18next.t('register.registerMain.error_text'), '', 'error');
            }
        }
    };

    return (
        <div name='register' className="flex justify-center items-center w-full flex-col pt-20 pb-20 duration-500">
            <form id='registerForm' onSubmit={handleRegistration} className='border-gray-500 rounded-xl md:w-2/3 w-[90vw]'>
                <div className='text-center md:pb-20 pb-10'>
                    <h1 className='md:text-[40px] text-[30px] text-gray-800 font-bold'>{i18next.t('register.registerMain.welcome')}</h1>
                </div>

                <div
                    className="place-items-center w-full flex md:flex-row flex-col justify-center md:gap-6 md:pr-0 md:pl-0 pr-6 pl-6">
                    <div
                        className="grid w-full md:grid-cols-2 grid-cols-1 gap-2 rounded-xl dark:bg-gray-700 p-2">
                        <div id='privateButton'>
                            <input
                                type="radio"
                                name="customertype"
                                id="1"
                                value="natural"
                                className="peer hidden"
                                checked={selectedOption === 'natural'}
                                onChange={(e) => {
                                    handleRadioChange(e); // Erste Aktion
                                    handleInputChange(e); // Zweite Aktion
                                }}
                            />
                            <label
                                htmlFor="1"
                                className={`block cursor-pointer hover:bg-blue-400 select-none text-white rounded-lg p-2 text-center ${
                                    selectedOption === 'natural'
                                        ? 'peer-checked:bg-[#3D73F5] peer-checked:font-bold peer-checked:text-white'
                                        : ''
                                }`}
                            >
                                {i18next.t('register.registerMain.privat')}
                            </label>
                        </div>

                        <div id='companyButton'>
                            <input
                                type="radio"
                                name="customertype"
                                id="4"
                                value="business"
                                className="peer hidden"
                                checked={selectedOption === 'business'}
                                onChange={(e) => {
                                    handleRadioChange(e); // Erste Aktion
                                    handleInputChange(e); // Zweite Aktion
                                }}
                            />
                            <label
                                htmlFor="4"
                                className={`block cursor-pointer hover:bg-blue-400 select-none text-white rounded-lg p-2 text-center ${
                                    selectedOption === 'business'
                                        ? 'peer-checked:bg-[#3D73F5] peer-checked:font-bold text-white peer-checked:text-white'
                                        : ''
                                }`}
                            >
                                {i18next.t('register.registerMain.company')}
                            </label>
                        </div>

                    </div>
                </div>
                <div className='flex md:flex-row flex-col justify-center md:gap-6 md:pr-0 md:pl-0 pr-6 pl-6'>
                    <div className="relative mt-8 w-full">
                        <select
                            id="gender"
                            name='gender'
                            aria-describedby="filled_success_help"
                            value={selectedGender}
                            onChange={(e) => {
                              handleInputChange(e);
                              handleGenderChange(e);
                            }}
                            className="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer duration-100"
                        >
                            <option value="" disabled>
                                {i18next.t('register.registerMain.chossegender')}
                            </option>
                            {genderOptions.map((option, index) => (
                                <option key={index} value={option.value}>
                                    {option.key}
                                </option>
                            ))}
                        </select>
                        <label
                            htmlFor="gender"
                            className="absolute text-sm text-gray-600 dark:text-gray-200 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                        >
                            {i18next.t('register.registerMain.country')}
                        </label>
                    </div>
                    <div className="relative mt-8 w-full">
                        <input type="text" id="firstName" aria-describedby="filled_success_help"
                               className="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer duration-100"
                               required
                               name='firstname'
                               onChange={handleInputChange}
                               placeholder=" "/>
                        <label htmlFor="firstName"
                               className="absolute text-sm text-gray-600 dark:text-gray-200 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
                            {i18next.t('register.registerMain.firstname')}</label>
                    </div>
                    <div className="relative mt-8 w-full">
                        <input type="text" id="lastName" aria-describedby="filled_success_help"
                               className="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer duration-100"
                               required
                               name='lastname'
                               onChange={handleInputChange}
                               placeholder=" "/>
                        <label htmlFor="lastName"
                               className="absolute text-sm text-gray-600 dark:text-gray-200 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
                            {i18next.t('register.registerMain.lastname')}</label>
                    </div>
                </div>
                {selectedOption === 'business' && (
                    <div className='flex md:flex-row flex-col justify-center md:gap-6 md:pr-0 md:pl-0 pr-6 pl-6'>
                        <div className="relative mt-8 w-full">
                            <input
                                type="text"
                                id="companyName"
                                name='company'
                                onChange={handleInputChange}
                                aria-describedby="filled_success_help"
                                className={`rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer duration-100 ${isCompanyNameRequired ? 'required' : ''}`}
                                placeholder=" "
                                required={isCompanyNameRequired}
                            />
                            <label
                                htmlFor="companyName"
                                className="absolute text-sm text-gray-600 dark:text-gray-200 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                            >
                                {i18next.t('register.registerMain.company_name')}
                            </label>
                        </div>
                    </div>
                )}
                <div className='flex md:flex-row flex-col justify-center md:gap-6 md:pr-0 md:pl-0 pr-6 pl-6'>
                    <div className="relative mt-8 w-full">
                        <input type="text" id="streetAndNumber" aria-describedby="filled_success_help"
                               className="rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer duration-100"
                               required
                               name='street'
                               onChange={handleInputChange}
                               placeholder=" "/>
                        <label htmlFor="streetAndNumber"
                               className="absolute text-sm text-gray-600 dark:text-gray-200 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
                            {i18next.t('register.registerMain.streetandnumber')}</label>
                    </div>
                </div>
                <div className='flex md:flex-row flex-col justify-center md:gap-6 md:pr-0 md:pl-0 pr-6 pl-6'>
                    <div className="relative mt-8 w-full">
                        <input type="text" id="plz" aria-describedby="filled_success_help"
                               className="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer duration-100"
                               required
                               name='plz'
                               onChange={handleInputChange}
                               placeholder=" "/>
                        <label htmlFor="plz"
                               className="absolute text-sm text-gray-600 dark:text-gray-200 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
                            {i18next.t('register.registerMain.postcode')}</label>
                    </div>
                    <div className="relative mt-8 w-full">
                        <input type="text" id="city" aria-describedby="filled_success_help"
                               className="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer duration-100"
                               required
                               name='city'
                               onChange={handleInputChange}
                               placeholder=" "/>
                        <label htmlFor="city"
                               className="absolute text-sm text-gray-600 dark:text-gray-200 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
                            {i18next.t('register.registerMain.city')}</label>
                    </div>
                </div>
                <div className='flex md:flex-row flex-col justify-center md:gap-6 md:pr-0 md:pl-0 pr-6 pl-6'>
                    {registerDropdownCountry(selectedCountry, setSelectedCountry, formData, setFormData)}
                </div>
                <div className="w-full text-center border-b border-gray-400 pb-6"></div>
                <div
                    className='flex md:flex-row-reverse flex-col-reverse justify-center md:gap-6 md:pr-0 md:pl-0 pr-6 pl-6'>
                    <div className="relative mt-8 w-full">
                        <input type="text" id="number" aria-describedby="filled_success_help"
                               className="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer duration-100"
                               required
                               name='callnumber'
                               onChange={handleInputChange}
                               placeholder=" "/>
                        <label htmlFor="number"
                               className="absolute text-sm text-gray-600 dark:text-gray-200 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
                            {i18next.t('register.registerMain.phonenumber')}</label>
                    </div>
                    {registerDropdownTel(selectedTel, setSelectedTel, formData, setFormData, handleInputChange)}
                </div>
                <div className='flex md:flex-row flex-col justify-center md:gap-6 md:pr-0 md:pl-0 pr-6 pl-6'>
                    <div className="relative mt-8 w-full">
                        <input type="email" id="email" aria-describedby="filled_success_help"
                               className="rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer duration-100"
                               required
                               name='email'
                               onChange={handleInputChange}
                               placeholder=" "/>
                        <label htmlFor="email"
                               className="absolute text-sm text-gray-600 dark:text-gray-200 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
                            {i18next.t('register.registerMain.email')}</label>
                    </div>
                    <div className="relative mt-8 w-full">
                        <input type="email" id="emailRepeat" aria-describedby="filled_success_help"
                               className="rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer duration-100"
                               required
                               name='emailrepeat'
                               onChange={handleInputChange}
                               placeholder=" "/>
                        <label htmlFor="emailrepeat"
                               className="absolute text-sm text-gray-600 dark:text-gray-200 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
                            {i18next.t('register.registerMain.repeatemail')}</label>
                    </div>
                </div>
                <div className="mt-8 w-full pr-6 pl-6 md:pr-0 md:pl-0">
                    <div className="flex items-center gap-2">
                        <input
                            className="text-violet-500 focus:ring-violet-300 border-gray-300 rounded-lg md:h-5 md:w-5"
                            type="checkbox"
                            value=""
                            required
                            name='acceptCompanycompliance'
                            id="acceptCompanycompliance" />
                        <label
                            className="text-sm md:text-base  hover:cursor-pointer"
                            htmlFor="acceptCompanycompliance">
                            {i18next.t('register.registerMain.acceptterms')}
                        </label>
                    </div>
                    <div className="flex items-center gap-2 mt-4">
                        <input
                            className="text-violet-500 focus:ring-violet-300 border-gray-300 rounded-lg md:h-5 md:w-5"
                            type="checkbox"
                            name='acceptDataprotect'
                            value=""
                            required
                            id="acceptDataprotect" />
                        <label
                            className="text-sm md:text-base  hover:cursor-pointer"
                            htmlFor="acceptDataprotect">
                            {i18next.t('register.registerMain.acceptpolicy')}

                        </label>
                    </div>
                </div>



                <div
                    className='flex md:flex-row flex-colt text-center justify-center md:gap-6 md:pr-0 md:pl-0 pr-6 pl-6'>
                    <div className="relative mt-8 w-full">
                        <motion.button
                            whileHover={{
                                scale: 1.1,
                                transition: 0.03
                            }}
                            whileTap={{
                                scale: 0.9,
                                transition: 0.03
                            }}
                            transition={{ease: "easeOut", duration: 0.03}}
                            href='#'
                            className='inline-block bg-[#3D73F5] p-3.5 md:pl-16 md:pr-16 pl-8 pr-8 rounded-3xl hover:bg-[#4169FC] duration-300 text-white font-bold'>
                            {i18next.t('register.registerMain.register_button')}
                        </motion.button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default RegisterMain;