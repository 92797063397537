import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Markdown from 'react-markdown';
import i18next from 'i18next'; // Importieren Sie i18next für die Übersetzungen
import i18n from "i18next";

const BlogMain = () => {
    const [blogEntries, setBlogEntries] = useState([]);
    const [expandedIndex, setExpandedIndex] = useState(-1);
    const [expandedText, setExpandedText] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // API-URL anpassen
        const apiUrl = 'https://api.cordes-hosting.net/website/api/blog';

        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                setTimeout(() => {
                    setBlogEntries(data);
                    console.log(data)
                    setIsLoading(false);
                }, 2000); // 2 Sekunden Verzögerung für die Transition
            })
            .catch(error => {
                console.error('Fehler beim Abrufen der Blogdaten:', error);
                setIsLoading(false);
            });
    }, []);

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const toggleExpanded = (index) => {
        if (expandedIndex === index) {
            setExpandedIndex(-1);
            setExpandedText({});
        } else {
            setExpandedIndex(index);
            setExpandedText({ [index]: i18n.language === 'de' || i18n.language === 'de-DE' ? blogEntries[index].content : blogEntries[index].content_en});
        }
    };

    return (
        <div className="grid justify-start items-start gap-12 md:grid-cols-1 pt-16 pb-16 lg:pl-64 lg:pr-64 md:pl-32 md:pr-32 pr-8 pl-8">
            <div className='w-full'>
                <h1 className='text-center md:text-[40px] text-[27px] font-bold text-gray-800 w-full'>{i18next.t('news.main.currentNews')}</h1>
            </div>
            {isLoading ? (
                <div className="text-center">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1.5 }} // Hier ist die 2-sekündige Transition für die Ladeanimation
                    >
                        <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">{i18next.t('news.main.loading')}</span>
                        </div>
                    </motion.div>
                </div>
            ) : (
                Array.isArray(blogEntries) && blogEntries.length > 0 ? (
                    blogEntries.map((entry, index) => (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 1.5 }}
                            key={index}
                            className="bg-white flex flex-col gap-4 rounded-lg p-4 shadow-md"
                        >
                            <h2 className="text-xl font-semibold">{i18n.language === 'de' || i18n.language === 'de-DE' ? entry.title : entry.title_en}</h2>
                            <p className="text-gray-500 text-sm mb-2">{entry.created_at}</p>
                            <AnimatePresence>
                                {expandedIndex === index ? (
                                    <motion.div
                                        key="content"
                                        initial={{ height: 0, opacity: 0 }}
                                        animate={{ height: "auto", opacity: 1 }}
                                        exit={{ height: 0, opacity: 0 }}
                                        transition={{ duration: 0.3 }} // Hier ist die Transition für den erweiterten Text
                                        className="text-gray-700 markdown-custom flex flex-col gap-4"
                                        style={{ overflow: "hidden", height: "auto" }}
                                    >
                                        <Markdown>{expandedText[index]}</Markdown>
                                    </motion.div>
                                ) : (
                                    <div className="text-gray-700 markdown-custom flex flex-col gap-4">
                                        <Markdown>{truncateText(i18n.language === 'de' || i18n.language === 'de-DE' ? entry.content : entry.content_en, 255)}</Markdown>
                                    </div>
                                )}
                            </AnimatePresence>
                            <motion.button
                                onClick={() => toggleExpanded(index)}
                                whileHover={{
                                    scale: 1.1,
                                    transition: { duration: 0.1 },
                                }}
                                whileTap={{
                                    scale: 0.9,
                                    transition: { duration: 0.1 },
                                }}
                                transition={{ ease: "easeOut", duration: 0.3 }}
                                href="#"
                                className="inline-block bg-[#3D73F5] w-[130px] p-1.5 md:pl-6 md:pr-6 pl-6 pr-6 rounded-3xl hover:bg-[#4169FC] duration-300 text-white font-bold"
                            >
                                {expandedIndex === index ? i18next.t('news.main.close') : i18next.t('news.main.readMore')}
                            </motion.button>
                        </motion.div>
                    ))
                ) : (
                    <div className="text-center">
                        <p>{i18next.t('news.main.noNews')}</p>
                    </div>
                )
            )}
        </div>
    );
};

export default BlogMain;
