import React, {useEffect} from 'react'
import '../index.css'
import Navbar from "../components/mainComps/navbar";
import MainRz from "../components/rz/mainRz";
import Footer from "../components/mainComps/footer";
import NextRz from "../components/rz/nextR";
import i18next from "i18next";

const Rz = () => {

    useEffect(() => {

        document.title = i18next.t('pagetitel.rz');
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <main>
            <Navbar/>
            <MainRz/>
            <NextRz/>
            <Footer/>
        </main>
    )
}

export default Rz;