// i18n.js
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import de from './configs/languages/de.json';
import en from './configs/languages/en.json';

i18n
    .use(LanguageDetector)
    .init({
        resources: {
            en: {
                translation: en, // Verweisen Sie hier auf Ihre englischen Übersetzungsdaten
            },
            de: {
                translation: de, // Verweisen Sie hier auf Ihre deutschen Übersetzungsdaten
            },
            'de-DE':{
                translation: de,
            }
        },
        fallbackLng: 'en', // Fallback-Sprache, wenn die gewählte Sprache nicht verfügbar ist
        interpolation: {
            escapeValue: false, // HTML-Tags in den Übersetzungen erlauben
        },
        detection: {
            order: ['navigator'] // Die Reihenfolge der Spracherkennung festlegen
        }
    });



export default i18n;
