import React from 'react';
import {RiShieldCheckLine} from "react-icons/ri";
import {MdVerified} from "react-icons/md";
import {FaLeaf} from "react-icons/fa";
import img1 from '../../assets/img/mainCubes/maincubes_architektur.webp'
import img2 from '../../assets/img/mainCubes/maincubes_connect.webp'
import img3 from '../../assets/img/mainCubes/maincubes_kuehlung.webp'
import img4 from '../../assets/img/mainCubes/maincubes_logo.webp'
import img5 from '../../assets/img/mainCubes/maincubes_strom.webp'
import img6 from '../../assets/img/mainCubes/maincubes_sicherheit.webp'


const NextRz = () => {
    return (
        <div className="">
            <div className='flex justify-center items-center'>
                <div className='grid md:grid-cols-3 grid-cols-1 w-full gap-12 mb-12 mr-4 ml-4'>
                    <div>
                        <img className='transition duration-300 hover:grayscale rounded-lg border border-gray-300' src={img1} />
                    </div>
                    <div>
                        <img className='transition duration-300 hover:grayscale rounded-lg border border-gray-300' src={img2} />
                    </div>
                    <div>
                        <img className='transition duration-300 hover:grayscale rounded-lg border border-gray-300' src={img3} />
                    </div>
                </div>
            </div>
            <div className='flex justify-center items-center'>
                <div className='grid md:grid-cols-3 grid-cols-1 w-full gap-12 mb-12 mr-4 ml-4'>
                    <div>
                        <img className='transition duration-300 hover:grayscale rounded-lg border border-gray-300' src={img4} />
                    </div>
                    <div>
                        <img className='transition duration-300 hover:grayscale rounded-lg border border-gray-300' src={img5} />
                    </div>
                    <div>
                        <img className='transition duration-300 hover:grayscale rounded-lg border border-gray-300' src={img6} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NextRz;