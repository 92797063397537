import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import ApiYaml from '../../configs/ApiSwagger.yaml'

const ApiMain = () => {
    return(
        <div className='swagger-ui'>
            <SwaggerUI url={ApiYaml} />
        </div>
    )
}

export default ApiMain;