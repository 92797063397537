import React, {useEffect} from 'react';
import Navbar from "../components/mainComps/navbar";
import Footer from "../components/mainComps/footer";
import MainActivate from "../components/activateAccount/mainActivate";
import i18next from "i18next";

const ActivateAcc = () => {

    useEffect(() => {

        document.title = i18next.t('pagetitel.activate');
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <main>
            <Navbar/>
            <MainActivate/>
            <Footer/>
        </main>
    )
}

export default ActivateAcc;