import React, {useEffect} from 'react';
import Navbar from "../components/mainComps/navbar";
import UptimeDisplay from "../components/serverStats/main";
import Footer from "../components/mainComps/footer";
import i18next from "i18next";

const ServerStatus = () => {

    useEffect(() => {

        document.title = i18next.t('pagetitel.serverstatus');
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <main>
            <Navbar/>
            <UptimeDisplay/>
            <Footer/>
        </main>
    )
}

export default ServerStatus;