import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import i18next from "i18next";

function DropdownAboutUs() {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const handleMouseEnter = () => {
        setTimeout(() => {
            setIsOpen(true)
        }, 100)
    };

    const handleMouseLeave = () => {
        setTimeout(() => {
            setIsOpen(false)
        }, 100)
    };

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    const dropdownVariants = {
        hidden: { opacity: 0, y: -10 },
        visible: { opacity: 1, y: 0 },
    };

    const arrowVariants = {
        normal: { rotate: 180 },
        rotated: { rotate: 0 },
    };

    return (
        <div className=''>
            <div
                className={`dropdown inline-block relative ${isOpen ? 'open' : ''}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
            >
                <button className="flex items-center justify-center pt-8 pb-8 p-3 text-sm hover:text-purple-600 hover:underline underline-offset-[13px] duration-300 decoration-[2px] rounded-full">
                    <span className="mr-1">{i18next.t('navbar.navbar_menu.dropdownAboutUs.aboutus')}</span>
                    <motion.svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        initial={false}
                        animate={isOpen ? 'rotated' : 'normal'}
                        variants={arrowVariants}
                    >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </motion.svg>
                </button>
                <AnimatePresence>
                    {isOpen && (
                        <motion.ul
                            className="dropdown-menu border border-gray-300 flex flex-col list-disc justify-center rounded-lg w-[134px] bg-gray-100 absolute text-gray-700 z-20"
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            variants={dropdownVariants}
                            transition={{ duration: 0.3 }}
                        >
                            <Link to='/aboutus'>
                            <li className="flex items-center text-sm cursor-pointer hover:text-purple-600 hover:underline underline-offset-[13px] duration-300 decoration-[2px] border-b border-gray-200 pl-4 pt-3 pb-3 w-[132px]">
                                {i18next.t('navbar.navbar_menu.dropdownAboutUs.company')}
                            </li>
                            </Link>
                            <Link to='/rz'>
                            <li className="flex items-center text-sm cursor-pointer hover:text-purple-600 hover:underline underline-offset-[13px] duration-300 decoration-[2px] border-gray-200 pl-4 pt-3 pb-3 w-[132px]">
                                {i18next.t('navbar.navbar_menu.dropdownAboutUs.datacenter')}
                            </li>
                            </Link>
                            <Link to='/productPort'>
                                <li className="flex items-center text-sm cursor-pointer hover:text-purple-600 hover:underline underline-offset-[13px] duration-300 decoration-[2px] border-gray-200 pl-4 pt-3 pb-3 w-[132px]">
                                    {i18next.t('navbar.navbar_menu.dropdownAboutUs.productport')}
                                </li>
                            </Link>
                        </motion.ul>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
}

export default DropdownAboutUs;
