import React, { useEffect, useState } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import i18next from "i18next";

import '../../index.css';

const Hero = () => {
    const { t } = useTranslation();
    const [jsonData3, setJsonData3] = useState(null);

    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://api.cordes-hosting.net/website/api/herobox", requestOptions)
            .then(response => response.json())
            .then(data => {
                setJsonData3(data);
            })
            .catch(error => console.log('error', error));
    }, []);

    return (
        <div className='flex flex-col justify-center items-center font-bold pt-6'>
            <h1 className='pt-10 mx-auto text-gray-800 text-center font-bold md:text-[45px] w-full pl-4 pr-4 text-[35px]'>{i18next.t('home.hero.arguments')}</h1>
            <div className='pt-24 flex md:flex-row flex-col items-center md:justify-around justify-between md:gap-14 min-[1006px]:gap-28 gap-16 '>
                {Array.isArray(jsonData3) ? (
                    jsonData3.map((item, index) => (
                        <div className='flex md:h-[500px] flex-col items-center justify-between gap-3 md:w-[25vw] w-3/4 border border-gray-300 shadow-2xl rounded-2xl' key={index}>
                            <h2 className='text-center md:text-xl text-[20px] md:pl-10 md:pr-10 md:pt-10 p1-2 pl-1 pt-2'>{i18n.language === 'de-DE' || i18n.language === 'de' ? item.title : item.title_en}</h2>
                            <p className='text-center max-[998px]:text-[12px] pl-10 pr-10'>{i18n.language === 'de-DE' || i18n.language === 'de' ? item.text : item.text_en}</p>
                            <div className='flex justify-center items-center w-full md:h-[380px] pt-2 mb-4'>
                                <Player
                                    autoplay
                                    loop
                                    speed={item.speed}
                                    src={item.lottie}
                                    className={item.classname}
                                    style={{ maxWidth: '70%'}}
                                />
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='pt-24 flex md:flex-row flex-col items-center md:justify-around justify-between md:gap-14 min-[1006px]:gap-28 gap-16 '>
                        <div className='flex h-[50vh] flex-col items-center justify-between gap-3 md:w-[25vw] w-3/4 border border-gray-300 shadow-2xl rounded-2xl'>
                            <h2 className='text-center md:text-xl text-[20px] pl-10 pr-10'>Marken Hardware!</h2>
                            <p className='text-center md:text-[15px] text-[17px] pl-10 pr-10'>AMD Epyc, DataCenter SSD, 10Gbit/s Anbindung. Und das im Herzen der IT Metropole in Frankfurt!</p>
                            <Player
                                autoplay
                                loop
                                speed={1600}
                                src="https://lottie.host/4a660bf3-40c5-471c-8c97-25ea21199f53/42Bf5iwYys.json"
                                className='pt-0 md:w-2/3 w-3/6'
                            />
                        </div>
                        <div className='flex h-[50vh] flex-col items-center justify-between gap-3 md:w-[25vw] w-3/4 border border-gray-300 shadow-2xl rounded-2xl'>
                            <h2 className='text-center md:text-xl text-[20px] pl-10 pr-10'>Der beste Support!</h2>
                            <p className='text-center md:text-[15px] text-[17px] pl-10 pr-10'>Durch unserer starke 750 Gbit/s ARBOR DDoS Protection lehren wir jeden DDoS das fürchten!</p>
                            <Player
                                autoplay
                                loop
                                src='https://lottie.host/f141788b-f855-484c-a7d2-5b004569b0e2/GDMrXuVafC.json'
                                className='pt-0 md:w-[1/12] w-3/6'
                            />
                        </div>
                        <div className='flex h-[50vh] flex-col items-center justify-between gap-3 md:w-[25vw] w-3/4 border border-gray-300 shadow-2xl rounded-2xl'>
                            <h2 className='text-center md:text-xl text-[20px] pl-10 pr-10'>Starke Sicherheit!</h2>
                            <p className='text-center md:text-[15px] text-[17px] pl-10 pr-10'>Durch unserer starke 750 Gbit/s ARBOR DDoS Protection lehren wir jeden DDoS das fürchten!</p>
                            <Player
                                autoplay
                                loop
                                src="https://lottie.host/2d4b1e22-5939-46bb-85e1-a5feed88c684/UEgKjnKrNp.json"
                                className='pt-0 md:w-2/3 w-3/6'
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Hero;
