import React from 'react';
import { PiShareNetworkBold } from "react-icons/pi";
import { FaLock } from "react-icons/fa6";
import { FaShieldAlt } from "react-icons/fa";
import '../../index.css'
import i18next from "i18next";

const DomainsNext = () => {
    return(
        <div className='flex flex-col w-full items-center pb-8'>
           <div className='flex justify-items-center items-center flex-col pb-8'>
               <h1 className='custom-h1 text-center px-2'>{i18next.t('domains.domainsNext.main_text')}</h1>
               <div className='w-full flex justify-center items-center text-center'>
                   <p className='text-center md:text-lg pt-8 px-4' >{i18next.t('domains.domainsNext.sub_text')}</p>
               </div>
           </div>
            <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1'>
                <div className="m-4 max-w-sm p-6 bg-gray-700 shadow-2xl border rounded-lg border-gray-700">
                    <PiShareNetworkBold className='w-10 h-10 text-gray-500 dark:text-gray-400 mb-3'/>
                    <a href="#">
                        <h5 className="mb-2 text-2xl font-semibold tracking-tight text-white dark:text-white">{i18next.t('domains.domainsNext.card1_titel')}</h5>
                    </a>
                    <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
                        {i18next.t('domains.domainsNext.card1_text')}</p>
                </div>


                <div className="m-4 max-w-sm p-6 bg-gray-700 shadow-2xl border rounded-lg border-gray-700">
                    <FaLock className='w-10 h-10 text-gray-500 dark:text-gray-400 mb-3'/>
                    <a href="#">
                        <h5 className="mb-2 text-2xl font-semibold tracking-tight text-white dark:text-white">{i18next.t('domains.domainsNext.card2_titel')}</h5>
                    </a>
                    <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">{i18next.t('domains.domainsNext.card2_text')}</p>
                </div>


                <div className="m-4 max-w-sm p-6 bg-gray-700 shadow-2xl border rounded-lg border-gray-700">
                    <FaShieldAlt className='w-10 h-10 text-gray-500 dark:text-gray-400 mb-3'/>
                    <a href="#">
                        <h5 className="mb-2 text-2xl font-semibold tracking-tight text-white dark:text-white">{i18next.t('domains.domainsNext.card3_titel')}</h5>
                    </a>
                    <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">{i18next.t('domains.domainsNext.card3_text')}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default DomainsNext;