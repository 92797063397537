import React, { useState } from "react";
import PortfolioCard, { isAnimating } from "./functions/PortfolioCard";
import vserverImg from '../../assets/img/productPort/2.webp'
import webspaceImg from '../../assets/img/productPort/3.webp'
import domainsImg from '../../assets/img/productPort/4.webp'
import webappsImg from '../../assets/img/productPort/1.webp'
import resellingImg from '../../assets/img/productPort/7.webp'
import gameserverImg from '../../assets/img/productPort/5.webp'
import bothostingImg from '../../assets/img/productPort/8.webp'
import teamspeakImg from '../../assets/img/productPort/6.webp'
import websiteImg from '../../assets/img/productPort/9.webp'
import dediImg from '../../assets/img/productPort/10.webp'
import managedImg from '../../assets/img/productPort/11.webp'
import consultantImg from '../../assets/img/productPort/12.webp'
import headerImg from '../../assets/img/productPortHeader.webp'
import ReactTyped from "react-typed";
import { motion } from "framer-motion";
import '../../index.css'
import i18next from "i18next";

import { useTranslation } from 'react-i18next'; // Import der i18next-Bibliothek

const ProductMain = () => {
    const { t } = useTranslation('productport'); // Verwendung der useTranslation-Hook

    const [showCard, setShowCard] = useState("all");

    const handleProject = (category) => {
        setShowCard(category);
    };

    return (
        <>
            <div className='flex flex-col background-productPort w-full items-center pt-24 pb-24 justify-center md:justify-center gap-6'>
                <div className='flex flex-col justify-center items-center text-center w-full gap-6'>
                    <h1 className='custom-h1'>
                        {i18next.t('productport.pageTitle')}
                    </h1>
                </div>
            </div>
            <section className="pt-12 pb-12 dark:bg-dark">
                <div className="container mx-auto">
                    <div className="w-full flex flex-wrap justify-center">
                        <div className="w-full px-4">
                            <ul className="flex flex-wrap justify-center mb-12 space-x-1">
                                <li className="mb-1">
                                    <button
                                        onClick={() => handleProject("all")}
                                        className={`custom-button ${
                                            showCard === "all"
                                                ? "activeClasses bg-primary text-white"
                                                : "inactiveClasses text-body-color dark:text-dark-6 hover:bg-primary hover:text-white"
                                        }`}
                                    >
                                        {i18next.t('productport.buttonLabels.allProducts')}
                                    </button>
                                </li>
                                <li className="mb-1">
                                    <button
                                        onClick={() => handleProject("hosting")}
                                        className={`custom-button ${
                                            showCard === "hosting"
                                                ? "activeClasses bg-primary text-white"
                                                : "inactiveClasses text-body-color dark:text-dark-6 hover:bg-primary hover:text-white"
                                        }`}
                                    >
                                        {i18next.t('productport.buttonLabels.hosting')}
                                    </button>
                                </li>
                                <li className="mb-1">
                                    <button
                                        onClick={() => handleProject(i18next.t('productport.buttonLabels.programmierung_label'))}
                                        className={`custom-button ${
                                            showCard === i18next.t('productport.buttonLabels.programmierung_label')
                                                ? "activeClasses bg-primary text-white"
                                                : "inactiveClasses text-body-color dark:text-dark-6 hover:bg-primary hover:text-white"
                                        }`}
                                    >
                                        {i18next.t('productport.buttonLabels.programmierung')}
                                    </button>
                                </li>
                                <li className="mb-1">
                                    <button
                                        onClick={() => handleProject("reselling")}
                                        className={`custom-button ${
                                            showCard === "reselling"
                                                ? "activeClasses bg-primary text-white"
                                                : "inactiveClasses text-body-color dark:text-dark-6 hover:bg-primary hover:text-white"
                                        }`}
                                    >
                                        {i18next.t('productport.buttonLabels.reselling')}
                                    </button>
                                </li>
                                <li className="mb-1">
                                    <button
                                        onClick={() => handleProject("services")}
                                        className={`custom-button ${
                                            showCard === "services"
                                                ? "activeClasses bg-primary text-white"
                                                : "inactiveClasses text-body-color dark:text-dark-6 hover:bg-primary hover:text-white"
                                        }`}
                                    >
                                        {i18next.t('productport.buttonLabels.services')}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-wrap px-4">
                        <PortfolioCard
                            ImageHref={vserverImg}
                            category={i18next.t('productport.vserver.category')}
                            title={i18next.t('productport.vserver.title')}
                            button={i18next.t('productport.vserver.info')}
                            text={i18next.t('productport.vserver.text')}
                            buttonHref="#"
                            showCard={showCard}
                        />
                        <PortfolioCard
                            ImageHref={webspaceImg}
                            category={i18next.t('productport.webspace.category')}
                            title={i18next.t('productport.webspace.title')}
                            button={i18next.t('productport.webspace.info')}
                            text={i18next.t('productport.webspace.text')}
                            buttonHref="#"
                            showCard={showCard}
                        />
                        <PortfolioCard
                            ImageHref={domainsImg}
                            category={i18next.t('productport.domains.category')}
                            title={i18next.t('productport.domains.title')}
                            button={i18next.t('productport.domains.info')}
                            text={i18next.t('productport.domains.text')}
                            buttonHref="#"
                            showCard={showCard}
                        />
                        <PortfolioCard
                            ImageHref={webappsImg}
                            category={i18next.t('productport.buttonLabels.programmierung')}
                            title={i18next.t('productport.webapps.title')}
                            button={i18next.t('productport.webapps.info')}
                            text={i18next.t('productport.webapps.text')}
                            buttonHref="#"
                            showCard={showCard}
                        />
                        <PortfolioCard
                            ImageHref={websiteImg}
                            category={i18next.t('productport.buttonLabels.programmierung')}
                            title={i18next.t('productport.website.title')}
                            button={i18next.t('productport.website.info')}
                            text={i18next.t('productport.website.text')}
                            buttonHref="#"
                            showCard={showCard}
                        />
                        <PortfolioCard
                            ImageHref={teamspeakImg}
                            category={i18next.t('productport.teamspeak.category')}
                            title={i18next.t('productport.teamspeak.title')}
                            button={i18next.t('productport.teamspeak.info')}
                            text={i18next.t('productport.teamspeak.text')}
                            buttonHref="#"
                            showCard={showCard}
                        />
                        <PortfolioCard
                            ImageHref={resellingImg}
                            category={i18next.t('productport.reselling.category')}
                            title={i18next.t('productport.reselling.title')}
                            button={i18next.t('productport.reselling.info')}
                            text={i18next.t('productport.reselling.text')}
                            buttonHref="#"
                            showCard={showCard}
                        />
                        <PortfolioCard
                            ImageHref={gameserverImg}
                            category={i18next.t('productport.gameserver.category')}
                            title={i18next.t('productport.gameserver.title')}
                            button={i18next.t('productport.gameserver.info')}
                            text={i18next.t('productport.gameserver.text')}
                            buttonHref="#"
                            showCard={showCard}
                        />
                        <PortfolioCard
                            ImageHref={bothostingImg}
                            category={i18next.t('productport.bothosting.category')}
                            title={i18next.t('productport.bothosting.title')}
                            button={i18next.t('productport.bothosting.info')}
                            text={i18next.t('productport.bothosting.text')}
                            buttonHref="#"
                            showCard={showCard}
                        />
                        <PortfolioCard
                            ImageHref={dediImg}
                            category={i18next.t('productport.dedicatedserver.category')}
                            title={i18next.t('productport.dedicatedserver.title')}
                            button={i18next.t('productport.dedicatedserver.info')}
                            text={i18next.t('productport.dedicatedserver.text')}
                            buttonHref="#"
                            showCard={showCard}
                        />
                        <PortfolioCard
                            ImageHref={managedImg}
                            category={i18next.t('productport.managedit.category')}
                            title={i18next.t('productport.managedit.title')}
                            button={i18next.t('productport.managedit.info')}
                            text={i18next.t('productport.managedit.text')}
                            buttonHref="#"
                            showCard={showCard}
                        />
                        <PortfolioCard
                            ImageHref={consultantImg}
                            category={i18next.t('productport.consulting.category')}
                            title={i18next.t('productport.consulting.title')}
                            button={i18next.t('productport.consulting.info')}
                            text={i18next.t('productport.consulting.text')}
                            buttonHref="#"
                            showCard={showCard}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default ProductMain;
