import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { motion } from "framer-motion";
import i18next from 'i18next'; // Importieren Sie i18next für die Übersetzungen

const MainActivate = () => {
    const { token } = useParams();

    const [registrationStatus, setRegistrationStatus] = useState('');

    const [formData, setFormData] = useState({
        password: '',
        passwordrepeat: ''
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleRegistration = async (event) => {
        event.preventDefault();
        if (formData.password !== formData.passwordrepeat) {
            Swal.fire({
                icon: 'error',
                title: i18next.t('activateaccount.mainactivate.errorTitle'),
                text: i18next.t('activateaccount.mainactivate.errorText')
            });
        } else {
            try {
                const response = await fetch(`https://api.cordes-hosting.net/website/api/activation/${token}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                if (response.status === 200) {
                    const data = await response.json();
                    Swal.fire({
                        title: i18next.t('activateaccount.mainactivate.successMessageTitle'),
                        text: i18next.t('activateaccount.mainactivate.successMessageText'),
                        icon: 'success',
                        timer: 5000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        willClose: () => {
                            window.location.href = `https://dashboard.cordes-hosting.net/?email=${data.user.email}`;
                        }
                    });
                } else if (response.status === 404) {
                    Swal.fire(i18next.t('activateaccount.mainactivate.invalidCode'), '', 'error');
                } else {
                    Swal.fire(i18next.t('activateaccount.mainactivate.genericError'), '', 'error');
                }
            } catch (error) {
                console.error('Error:', error);
                Swal.fire(i18next.t('activateaccount.mainactivate.genericError'), '', 'error');
            }
        }
    };

    return (
        <form onSubmit={handleRegistration}>
            <div className='flex flex-col items-center justify-center gap-4 md:h-[500px] h-[680px] w-full max-[467px]:w-4/6 mx-auto text-center'>
                <h1 className='custom-h1 mt-12'>{i18next.t('activateaccount.mainactivate.title')}</h1>
                <p className='custom-p mt-4'>{i18next.t('activateaccount.mainactivate.description')}</p>
                <div className="relative mt-8 md:w-[400px] w-[200px] max-[246px]:w-auto">
                    <input
                        type="password"
                        id="password"
                        aria-describedby="filled_success_help"
                        className="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer duration-100"
                        required
                        name='password'
                        onChange={handleInputChange}
                        placeholder=" "
                    />
                    <label htmlFor="password" className="absolute text-sm text-gray-600 dark:text-gray-200 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
                        {i18next.t('activateaccount.mainactivate.passwordLabel')}
                    </label>
                </div>
                <div className="relative mt-8 md:w-[400px] w-[200px] max-[246px]:w-auto">
                    <input
                        type="password"
                        id="passwordrepeat"
                        aria-describedby="filled_success_help"
                        className="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer duration-100"
                        required
                        name='passwordrepeat'
                        onChange={handleInputChange}
                        placeholder=" "
                    />
                    <label htmlFor="passwordrepeat" className="absolute text-sm text-gray-600 dark:text-gray-200 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
                        {i18next.t('activateaccount.mainactivate.passwordRepeatLabel')}
                    </label>
                    <motion.button
                        whileHover={{
                            scale: 1.1,
                            transition: 0.03
                        }}
                        whileTap={{
                            scale: 0.9,
                            transition: 0.03
                        }}
                        transition={{ ease: "easeOut", duration: 0.03 }}
                        href='#'
                        className='inline-block bg-[#3D73F5] p-2.5 pl-6 pr-6 mt-12 mb-8 rounded-3xl hover:bg-[#4169FC] duration-300 text-white font-bold'>
                        {i18next.t('activateaccount.mainactivate.registerButton')}
                    </motion.button>
                </div>
            </div>
        </form>
    )
}

export default MainActivate;
