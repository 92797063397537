import React, {useEffect} from 'react'
import Navbar from '../components/mainComps/navbar'
import MainAbout from '../components/aboutus/aboutMainPart'
import '../index.css'
import AboutUsNext from "../components/aboutus/aboutUsNext";
import Footer from "../components/mainComps/footer";
import i18next from "i18next";

const AboutUs = () => {

    useEffect(() => {

        document.title = i18next.t('pagetitel.aboutUs');
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <main>
            <Navbar/>
            <MainAbout/>
            <AboutUsNext/>
            <Footer/>
        </main>
    )
}

export default AboutUs;
