import "swagger-ui-react/swagger-ui.css"
import Navbar from "../components/mainComps/navbar";
import Footer from "../components/mainComps/footer";
import ApiMain from "../components/apiDoku/apimain";
import {useEffect} from "react";
import i18next from "i18next";

const ApiDoku = () => {

    useEffect(() => {

        document.title = i18next.t('pagetitel.apidoku');
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <main>
            <Navbar/>
            <ApiMain/>
            <Footer/>
        </main>
    )
}

export default ApiDoku;