import React, {useEffect} from 'react';
import Navbar from "../components/mainComps/navbar";
import Footer from "../components/mainComps/footer";
import TechnicMain from "../components/technic/technicMain";
import TechnicMain2 from "../components/technic/technicMain2";
import i18next from "i18next";

const Technic = () => {

    useEffect(() => {

        document.title = i18next.t('pagetitel.technic');
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <main>
            <Navbar/>
            <TechnicMain/>
            <TechnicMain2/>
            <Footer/>
        </main>
    )
}

export default Technic;