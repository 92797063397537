import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from "i18next";

const AboutUsNext = () => {

    const { t } = useTranslation();

    return (

        <div className="container mx-auto md:px-24 px-8 py-8 mt-8 mb-8">
            <h1 id='aboutUsDown' className="text-3xl md:text-4xl lg:text-5xl font-bold text-start mb-6">
                {i18next.t('aboutus.aboutUsNext.aboutus')}
            </h1>
            <div className="text-lg md:text-xl space-y-4">
                <p className='text-[17px]'>
                    {i18next.t('aboutus.aboutUsNext.firstpart')}
                </p>
                <p className='text-[17px]'>
                    {i18next.t('aboutus.aboutUsNext.secondpart')}
                </p>
                <p className='text-[17px]'>
                    {i18next.t('aboutus.aboutUsNext.thirdpart')}
                </p>
                <p className='text-[17px]'>
                    {i18next.t('aboutus.aboutUsNext.fourthpart')}
                </p>
                <p className='text-[17px]'>
                    {i18next.t('aboutus.aboutUsNext.fivepart')}
                </p>
                <p className='text-[17px]'>
                    {i18next.t('aboutus.aboutUsNext.sixtpart')}
                </p>
            </div>
        </div>

    )
}

export default AboutUsNext;