import React from 'react';
import i18next from 'i18next'; // Importieren Sie i18next für die Übersetzungen

const ResellerSubMain2 = () => {
    return (
        <div className='flex flex-col w-full items-center pb-16'>
            <div className="w-3/4 border-t border-gray-400 pt-8"></div>
            <div className='w-3/4'>
                <h1 className='font-bold text-2xl'>
                    {i18next.t('reselling.submain2.description')}
                </h1>
            </div>
            <div className='flex md:flex-row flex-col justify-center w-3/4 gap-16 pt-12'>
                <div className='flex flex-col justify-center md:gap-16 gap-4 text-[17px]'>
                    <h1 className='flex'>
                        {i18next.t('reselling.submain2.specialConditions')}
                    </h1>
                    <h1>
                        {i18next.t('reselling.submain2.benefitFromExperience')}
                    </h1>
                </div>
                <div className="border-l border-gray-400 pt-8 md:flex hidden justify-center"></div>
                <div className='flex flex-col md:gap-16 gap-4 text-[17px]'>
                    <h1>
                        {i18next.t('reselling.submain2.uniqueSupport')}
                    </h1>
                    <h1>
                        {i18next.t('reselling.submain2.setNewStandards')}
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default ResellerSubMain2;
