import React from 'react';
import ReactTyped from "react-typed";
import {motion} from "framer-motion";
import i18next from "i18next";

const TechnicMain = () => {
    return(
        <div className='w-full flex flex-col background-technic h-screen-minus-navbar items-center justify-center gap-6 shadow-2xl'>
            <div className='flex flex-col justify-center items-center h-full w-full gap-6'>
                <h1 className='text-gray-50 md:text-[45px] text-[25px] text-center w-full md:w-2/3 font-bold pl-4 pr-4 md:pr-0 md:pl-0'>
                    <ReactTyped strings={[i18next.t('technic.main.maintext')]} typeSpeed={60}/>
                </h1>
                <motion.p animate={{y: [40, 0]}}
                          transition={{ease: "easeIn", duration: 0.9}}
                          className='font-bold h-[366] pl-8 pr-8 md:pr-0 md:pl-0 text-gray-50 text-center w-full md:pb-8 md:w-2/3'>
                    {i18next.t('technic.main.subtext')}
                </motion.p>
            </div>

        </div>
    )
}

export default TechnicMain;