import Navbar from "../components/mainComps/navbar";
import Footer from "../components/mainComps/footer";
import DomainsMain from "../components/domains/domainsStart";
import './../index.css'
import DomainsSubStart from "../components/domains/domainsSubStart";
import DomainsNext from "../components/domains/domainsNext";
import {useEffect} from "react";
import i18next from "i18next";

const Domains = () => {

    useEffect(() => {

        document.title = i18next.t('pagetitel.domains');
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <main>
        <Navbar/>
            <DomainsMain/>
            <DomainsSubStart/>
            <DomainsNext/>
            <Footer/>
        </main>
    )
}

export default Domains;