import React, {useEffect} from 'react';
import hljs from 'highlight.js'
import php from 'highlight.js/lib/languages/php';
import './styles/a11y-dark.min.css'

const CodeWindow = ({ code, language }) => {
    useEffect(() => {
        hljs.registerLanguage('php', php);
        hljs.highlightAll(); // Hervorhebung initialisieren
    }, [code, language]);
    return (
        <div className="bg-gray-800 text-white mx-auto p-4 rounded-lg md:w-[500px] w-full shadow-lg">
            <div className="flex space-x-2">
                <div className="md:h-3 md:w-3 h-3 w-3 bg-red-500 rounded-full"></div>
                <div className="md:h-3 md:w-3 h-3 w-3 bg-yellow-500 rounded-full"></div>
                <div className="md:h-3 md:w-3 h-3 w-3 bg-green-500 rounded-full"></div>
            </div>
            <div>

            </div>
            <pre className="mt-4">
        <code className='overflow-auto rounded-xl !scrollbar-thumb-rounded-full !scrollbar-thin !scrollbar-track-rounded-full !scrollbar !scrollbar-thumb-slate-700 !scrollbar-track-slate-300 w-full'>{code}</code>
      </pre>
        </div>
    );
};

export default CodeWindow;
