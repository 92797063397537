import React from 'react';
import Server from '../../assets/img/server.webp'
import ReactTyped from "react-typed";
import { FaArrowDownLong } from "react-icons/fa6";
import {motion} from "framer-motion";
import { Link, animateScroll as scroll } from "react-scroll";
import { useTranslation } from 'react-i18next';
import i18next from "i18next";

const MainAbout = () => {

    const { t } = useTranslation();

    return (

        <div className='w-full flex flex-col background h-screen-minus-navbar items-center justify-start pt-48 md: md:justify-center gap-6'>
            <div className='flex flex-col justify-center md:items-center md:pb-72 w-full gap-6'>
                <h1 className='custom-h1 !text-white'>
                    <ReactTyped strings={[i18next.t('aboutus.aboutMainPart.whoweare')]} typeSpeed={60}/>
                </h1>
                <motion.div
                    className='flex justify-center'
                animate={{ y: ["0%", "10%", "0%"]}}
                transition={{
                    duration: 2,
                    ease: "easeInOut",
                    repeat: Infinity
                }}
                >
                    <Link
                        to='aboutUsDown'
                        smooth={true}
                        offset={-70}
                        durration={500}
                    >
                    <FaArrowDownLong className='text-white h-[60px] cursor-pointer w-[60px] mt-24'/>
                    </Link>
                </motion.div>
            </div>
        </div>

    )
}

export default MainAbout;