import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/home";
import AboutUs from "./pages/aboutUs";
import Reselling from "./pages/reselling";
import Register from "./pages/register";
import Blog from './pages/blog';
import Technic from "./pages/technik";
import Domains from "./pages/domains";
import ApiDoku from "./pages/apidoku";
import ServerStatus from "./pages/serverstatus";
import { Helmet } from 'react-helmet';
import Rz from "./pages/rz";
import ActivateAcc from "./pages/activateAcc";
import Impress from "./pages/impress";
import Contact from "./pages/contact";
import ProductPort from "./pages/productPort";
import { useTranslation } from 'react-i18next'; // Importieren Sie useTranslation aus react-i18next
import i18n from './useTranslations';
import Resource from "./pages/resource";
import Support from "./pages/support";
import Meeting from "./pages/meeting"; // Importieren Sie Ihre i18n-Konfiguration

function App() {
    const { t } = useTranslation();

    useEffect(() => {
        // Laden des i18next-Scripts
        import('i18next-http-backend').then((module) => {
            i18n
                .use(module.default)
                .init({
                    backend: {
                        loadPath: './configs/languages/{{ns}}.json', // Pfad zu Ihren Übersetzungsdateien
                    },
                });
        });

        const script = document.createElement('script');
        script.src = "https://analytics.cordes-cloud.net/script.js";
        script.async = true;
        script.setAttribute('data-website-id', "ee517238-aaea-4551-97df-a38aa748154e");

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        // Function to embed RocketChat live chat
        (function(w, d, s, u) {
            w.RocketChat = function(c) { w.RocketChat._.push(c) };
            w.RocketChat._ = [];
            w.RocketChat.url = u;
            var h = d.getElementsByTagName(s)[0],
                j = d.createElement(s);
            j.async = true;
            j.src = 'https://chat.cordes-hosting.net/livechat/rocketchat-livechat.min.js?_=201903270000';
            h.parentNode.insertBefore(j, h);
        })(window, document, 'script', 'https://chat.cordes-hosting.net/livechat');

        return () => {
            // Cleanup when the component unmounts
            const script = document.querySelector('script[src="https://chat.cordes-hosting.net/livechat/rocketchat-livechat.min.js?_=201903270000"]');
            if (script) {
                script.parentNode.removeChild(script);
            }
        };
    }, []);

    return (
            <Router>
                <Helmet>
                    <meta name="description" content="Cordes-Hosting bietet professionelle Webhosting-Lösungen, Domainregistrierung und dedizierte Server mit erstklassigem Support und höchster Verfügbarkeit." />
                    <meta name="keywords" content="Webhosting, Domains, Serverhosting, IT-Infrastruktur, Cordes-Hosting, Cloud-Services" />
                    <meta name="author" content="Steven Dautrich" />
                    <meta name="robots" content="index,follow" />
                    <meta name="theme-color" content="#000000"/>
                    <meta property="og:description" content="Cordes-Hosting bietet professionelle Webhosting-Lösungen, Domainregistrierung und dedizierte Server mit erstklassigem Support und höchster Verfügbarkeit." />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://www.cordes-hosting.de" />
                    <meta property="og:image" content="https://www.cordes-hosting.net/logo.png" />
                </Helmet>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/aboutus" component={AboutUs} />
                    <Route exact path='/reselling' component={Reselling}/>
                    <Route exact path='/register' component={Register}/>
                    <Route exact path='/blog' component={Blog}/>
                    <Route exact path='/technic' component={Technic}/>
                    <Route exact path='/domains' component={Domains}/>
                    <Route exact path='/apidoku' component={ApiDoku}/>
                    <Route exact path='/status' component={ServerStatus}/>
                    <Route exact path='/rz' component={Rz}/>
                    <Route exact path='/activateAccount/:token' component={ActivateAcc}/>
                    <Route exact path='/impress' component={Impress}/>
                    <Route exact path='/contact' component={Contact}/>
                    <Route exact path='/productPort' component={ProductPort}/>
                    <Route exact path='/resource' component={Resource}/>
                    <Route exact path='/support' component={Support}/>
                    <Route exact path='/meeting' component={Meeting}/>
                </Switch>
            </Router>
    );
}

export default App;
