import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'tailwindcss/tailwind.css';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const UptimeDisplay = () => {


    const [groupedChartData, setGroupedChartData] = useState({});

    const formatDataForChart = (serverData) => {
        const timestamps = serverData.values.map(value => value[0]);
        const values = serverData.values.map(value => value[1]);

        return {
            serverName: serverData.monitor_name,
            labels: timestamps,
            datasets: [
                {
                    label: `Monitor ${serverData.monitor_name}`,
                    backgroundColor: values.map(value => {
                        return value === 1 ? 'rgba(33,204,106,.8)' :
                            value === 0 ? 'rgba(221,73,55,.8)' :
                                'rgba(58, 87, 232, .8)';  // Assuming 2 is for maintenance
                    }),
                    borderColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 1,
                    minBarLength: 100,
                    data: values
                },
            ]
        };
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://health.vlog01.cordes-hosting.net/statusdata');
                const data = await response.json();
                const groupedData = {};

                Object.entries(data.statusData).forEach(([groupName, serverArray]) => {
                    groupedData[groupName] = serverArray.map(formatDataForChart);
                });

                setGroupedChartData(groupedData);
            } catch (error) {
                console.error('Fehler beim Abrufen der Daten:', error);
            }
        };

        fetchData();
    }, []);

    const options = {
        animation: false,
        maintainAspectRatio: false,
        barPercentage: 0.9,
        categoryPercentage: 1,
        scales: {
            x: {
                display: false,
                title: {
                    display: true,
                    text: 'Zeit'
                }
            },
            y: {
                display: false,
                title: {
                    display: true,
                    text: 'Wert'
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'top'
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.label} => ${context.parsed.y === 1 ? 'UP' : (context.parsed.y === 0 ? 'DOWN' : 'MAINTENANCE')}`;
                    },
                    title: function () {
                        return '';
                    }
                }
            }
        }
    };



    return (
        <div className="p-4 min-h-screen">
            <h1 className="custom-h1 mb-8">Server-Status</h1>
            {Object.entries(groupedChartData).map(([groupName, charts], groupIndex) => (
                <div key={groupIndex} className="mb-6">
                    <div className="card-background-resource bg-opacity-25 p-5 rounded-lg shadow-lg">
                        <h2 className="text-xl font-semibold text-gray-800 mb-4">{groupName}</h2>
                        {charts.map((chartData, index) => (
                            <div key={index} className="mb-4">
                                <h3 className="text-md font-semibold text-gray-800 mb-2">{chartData.serverName}</h3>
                                <div className="!h-[30px] bg-transparent rounded-lg overflow-hidden relative">
                                    <Bar data={chartData} className='absolute h-full top-0 left-0 w-full' options={options} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );

};

export default UptimeDisplay;
