import React from 'react';
import {SiSamsung, SiSupermicro, SiAsus, SiAmd, SiWesterndigital, SiNvidia} from 'react-icons/si'
import AmdEpyc from '../../assets/img/AMD_EPYC_ILLUSTARTION.webp'
import HpSwitch from '../../assets/img/HP_SWITCH_ILLUSTRATION.webp'
import {MdOutlineSdStorage} from "react-icons/md";
import i18next from "i18next";

const TechnicMain2 = () => {
    return (
        <div className='w-full flex flex-col items-center justify-start md:justify-around gap-6 pt-8 pb-8 duration-500'>
            <div className='w-[90vw] h-full flex justify-center text-center items-center'>
                <h1 className='md:text-[45px] text-[35px] font-bold'>{i18next.t('technic.main2.maintext')}</h1>
            </div>
            <div className='border-b w-2/3 border-gray-300 my-4 transition-shadow duration-500 hover:shadow-md'></div>
            <div className='flex justify-center md:gap-24 md:flex-row-reverse max-[986px]:!flex-col gap-16 mt-6'>
                <ul className='flex-1 font-bold md:w-full w-[80vw] flex flex-col pb-4 list-disc justify-center items-start gap-1'>
                    <li className='pb-6 md:w-full'>{i18next.t('technic.main2.subtexte.first.text1')}</li>
                    <li className='pb-6 md:w-full'>{i18next.t('technic.main2.subtexte.first.text2')}</li>
                    <li className='pb-6 md:w-full'>{i18next.t('technic.main2.subtexte.first.text3')}</li>
                    <li className='pb-6 md:w-full'>{i18next.t('technic.main2.subtexte.first.text4')}</li>
                    <li className='pb-6 md:w-full'>{i18next.t('technic.main2.subtexte.first.text5')}</li>
                </ul>
                <div className='flex justify-center items-center md:w-[420px] transition-transform duration-500 hover:scale-105'>
                    <img src={AmdEpyc} alt="Server Bild" className='md:w-[420px] w-[70vw]' />
                </div>
            </div>

            <div className='border-b w-2/3 border-gray-300 my-4 transition-shadow duration-500 hover:shadow-md'>

            </div>

            <div className='flex justify-center md:gap-24 md:flex-row max-[986px]:!flex-col gap-16 mt-6'>
                <ul className='flex-1 font-bold md:w-full w-[80vw] flex flex-col pb-4 list-disc justify-center items-start gap-1'>
                    <li className='pb-6 md:w-full'>{i18next.t('technic.main2.subtexte.second.text1')}</li>
                    <li className='pb-6 md:w-full'>{i18next.t('technic.main2.subtexte.second.text2')}</li>
                    <li className='pb-6 md:w-full'>{i18next.t('technic.main2.subtexte.second.text3')}</li>
                    <li className='pb-6 md:w-full'>{i18next.t('technic.main2.subtexte.second.text4')}</li>
                    <li className='pb-6 md:w-full'>{i18next.t('technic.main2.subtexte.second.text5')}</li>
                </ul>
                <div className='flex justify-center items-center md:w-[420px] transition-transform duration-500 hover:scale-105'>
                    <img src={HpSwitch} alt="Server Bild" className='md:w-[420px] w-[70vw]' />
                </div>
            </div>
            <div className='flex items-center justify-center pt-12'>
                <div className='grid md:grid-cols-6 max-[984px]:!grid-cols-3 max-[410px]:!grid-cols-2 gap-12'>
                    <div className='transition-transform duration-500 hover:scale-110'>
                        <SiAmd className='md:text-[120px] text-[90px]' />
                    </div>
                    <div>
                        <SiSamsung className='md:text-[120px] text-[90px]'/>
                    </div>
                    <div>
                        <SiSupermicro className='md:text-[120px] text-[90px]'/>
                    </div>
                    <div>
                        <SiAsus className='md:text-[120px] text-[90px]'/>
                    </div>
                    <div>
                        <SiWesterndigital className='md:text-[120px] text-[90px]'/>
                    </div>
                    <div>
                        <SiNvidia className='md:text-[120px] text-[90px]'/>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default TechnicMain2;
