import React from 'react';
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const SupportCard = ({ title, text, img, link, textButton, textSecond }) => {

    const { t } = useTranslation();

    return (
        <div className='flex justify-center items-center w-full px-4'>
            <div className='card-background-resource md:w-[480px] h-[620px] w-full mt-8 mb-8 rounded-3xl shadow-2xl flex flex-col'>
                <div className='w-full md:px-16 px-4 text-left flex flex-col flex-grow justify-between'> {/* Hier flex-grow hinzugefügt */}
                    <div className='flex flex-col justify-center items-center flex-grow'> {/* Flex container für den Inhalt oben */}
                        <div className='flex justify-start w-full'>
                            {img}
                        </div>

                        <div className='w-full flex flex-col justify-start gap-4 h-full mt-16'>
                            <h2 className='custom-h2'>{title}</h2>
                            {text}
                            {textSecond}
                        </div>
                    </div>
                    {textButton ? (
                        <div className='w-full flex justify-start pb-8'> {/* Container für den Button unten */}
                            <motion.a
                                download
                                href={link}
                                whileHover={{ scale: 1.1, transition: 0.03 }}
                                whileTap={{ scale: 0.9, transition: 0.03 }}
                                transition={{ ease: "easeOut", duration: 0.03 }}
                                className='flex bg-[#3D73F5] md:w-[230px] text-center w-2/3 p-2.5 pl-5 pr-5 items-center justify-center rounded-3xl hover:bg-[#4169FC] duration-300 text-white font-bold'
                            >
                                {textButton}
                            </motion.a>
                        </div>
                    ) : ''}

                </div>
            </div>
        </div>
    );
}

export default SupportCard;
