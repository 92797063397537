import React from 'react';
import SupportCard from "./supportCard";
import { IoCallOutline } from "react-icons/io5";
import i18next from "i18next";
import { CiMail } from "react-icons/ci";
import { IoIosHelpBuoy } from "react-icons/io";


const SupportMain = () => {


    return(
        <div className='flex justify-start items-center flex-col w-full'>
            <h1 className='pt-20 custom-h1'>{i18next.t('support.headerText')}</h1>
            <h2 className='custom-h2 pt-8'>{i18next.t('support.headerSecondText')}</h2>
            <div className='pt-16 pb-8 grid lg:grid-cols-3 max-[1105px]:!grid-cols-2 max-[900px]:!grid-cols-1 justify-center items-center'>
                <SupportCard
                    title={i18next.t('support.firstCard.title')}
                    text={<p className='custom-p-card'>{i18next.t('support.firstCard.text')}</p>}
                    textSecond={<a className='custom-p-card' href='tel:+497214807690'>+49 (0) 721 4807690</a>}
                    img={<IoCallOutline className='mt-20 text-[90px] text-[#3D73F5]'/>}
                    link='tel:+497214807690'
                    textButton={i18next.t('support.firstCard.textButton')}
                />
                <SupportCard
                    title={i18next.t('support.secondCard.title')}
                    text={<p className="custom-p-card">{i18next.t('support.secondCard.text')}</p>}
                    textSecond={
                    <div className='custom-p-card flex flex-col gap-2'>
                        <a href='mailto:support@cordes-hosting.de'>support@cordes-hosting.de</a>
                        <a href='mailto:technik@cordes-hosting.de'>technik@cordes-hosting.de</a>
                        <a href='mailto:buchhaltung@cordes-hosting.de'>buchhaltung@cordes-hosting.de</a>
                    </div>
                    }
                    img={<CiMail className='mt-20 text-[90px] text-[#3D73F5]'/>}
                    textButton=''
                />
                <SupportCard
                    title={i18next.t('support.thirdCard.title')}
                    text={<p className="custom-p-card">{i18next.t('support.thirdCard.text')}</p>}
                    textSecond={
                    <div className='custom-p-card'>
                        <a href='https://support.cordes-hosting.de/' target='_blank' className='custom-p-card'>support.cordes-hosting.de</a>
                    </div>

                    }
                    img={<IoIosHelpBuoy className='mt-20 text-[90px] text-[#3D73F5]'/>}
                    link='https://support.cordes-hosting.de/'
                    textButton={i18next.t('support.thirdCard.textButton')}
                />
            </div>
        </div>
    )
}

export default SupportMain;