import BlogMain from '../components/blog/blogMain'
import Navbar from '../components/mainComps/navbar'
import Footer from "../components/mainComps/footer";
import {useEffect} from "react";
import i18next from "i18next";


const Blog = () => {

    useEffect(() => {
        // Setzen Sie den Titel der Seite
        document.title = i18next.t('pagetitel.blog');
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <main>
            <Navbar/>
            <BlogMain/>
            <Footer/>
        </main>
    );

}

export default Blog;
