import React from 'react';
import {RiShieldCheckLine} from "react-icons/ri";
import {MdVerified} from "react-icons/md";
import {FaLeaf} from "react-icons/fa";
import MainCubes from '../../assets/img/Maincubes-2.webp'
import {motion} from "framer-motion";
import i18next from "i18next";


const MainRz = () => {
    return (
        <div className="m-6 mt-12 mb-40">
            <div className='flex md:flex-row flex-col items-center justify-center'>
                <h2 className="custom-h1-noncenter md:mb-0 md:text-right mb-8 text-center">
                    {i18next.t('rz.mainRz.maintext')}
                </h2>
                <div className="md:h-40 md:w-80 h-32 w-52 mb-12">
                    <img src={MainCubes} className='w-[700px]'/>
                </div>
            </div>
            <p className="custom-p-bold text-center md:mb-12 mb-4">
                {i18next.t('rz.mainRz.subtext')}
            </p>
            <div className='flex justify-center'>
                <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 md:gap-16 gap-8'>
                    <div
                        className="m-4 max-w-sm p-6 dark:bg-gray-700 shadow-2xl border rounded-lg dark:border-gray-700 border-gray-500">
                        <motion.div whileHover={{ y: -5.4 }}
                                    style={{ transformOrigin: "center"}}
                                    transition={{ type: "spring" }}>
                            <RiShieldCheckLine className='w-10 h-10 text-gray-700 dark:text-gray-400 mb-3'/>
                        </motion.div>

                        <a href="#">
                            <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                                {i18next.t('rz.mainRz.card1_main')}
                            </h5>
                        </a>
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                            {i18next.t('rz.mainRz.card1_subtext')}
                        </p>
                    </div>


                    <div
                        className="m-4 max-w-sm p-6 dark:bg-gray-700 shadow-2xl border rounded-lg dark:border-gray-700 border-gray-500">
                        <motion.div whileHover={{ y: -5.4 }}
                                    style={{ transformOrigin: "center"}}
                                    transition={{ type: "spring" }}>
                        <MdVerified className='w-10 h-10 text-gray-700 dark:text-gray-400 mb-3'/>
                        </motion.div>
                        <a href="#">
                            <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                                {i18next.t('rz.mainRz.card2_main')}
                            </h5>
                        </a>
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                            {i18next.t('rz.mainRz.card2_subtext')}
                        </p>
                    </div>


                    <div className='sm:flex sm:justify-center md:w-full sm:w-[96vw]'>
                        <div
                            className="m-4 max-w-sm p-6 dark:bg-gray-700 shadow-2xl border rounded-lg dark:border-gray-700 border-gray-500">
                            <motion.div whileHover={{ y: -5.4 }}
                                        style={{ transformOrigin: "center"}}
                                        transition={{ type: "spring" }}>
                            <FaLeaf className='w-10 h-10 text-gray-700 dark:text-gray-400 mb-3'/>
                            </motion.div>
                            <a href="#">
                                <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                                    {i18next.t('rz.mainRz.card3_main')}
                                </h5>
                            </a>
                            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                                {i18next.t('rz.mainRz.card3_subtext')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default MainRz;