import Navbar from "../components/mainComps/navbar";
import Footer from "../components/mainComps/footer";
import MainContact from "../components/contact/mainContact";
import {useEffect} from "react";
import i18next from "i18next";

const Contact = () => {

    useEffect(() => {

        document.title = i18next.t('pagetitel.contact');
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <main>
            <Navbar/>
            <MainContact/>
            <Footer/>
        </main>
    )
}

export default Contact;