import React, {useEffect} from 'react';
import Navbar from "../components/mainComps/navbar";
import Footer from "../components/mainComps/footer";
import RegisterMain from "../components/register/registerMain";
import i18next from "i18next";
const Register = () => {

    useEffect(() => {

        document.title = i18next.t('pagetitel.register');
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <main>
            <Navbar/>
            <RegisterMain/>
            <Footer/>
        </main>
    )
}

export default Register;