import axios from "axios";

export async function sendUmami(event){
    try{
        const data = {
            payload: {hostname: window.location.hostname,
                language: navigator.language,
                referrer: document.referrer,
                screen: `${window.screen.width}-x${window.screen.height}`,
                url: window.location.pathname,
                website: 'ee517238-aaea-4551-97df-a38aa748154e',
                name: event,
            },
            type: 'event'
        }
        await axios.post('https://analytics.cordes-cloud.net/api/send', data)
            .then((response) => {

            }).catch((error) => {
                console.log('Es ist ein Fehler aufgetreten: ' + error)
            })
    }catch(error){
        console.log('Es ist ein Fehler aufgetreten: ' + error)
    }

}