import React, {useEffect} from 'react';
import Navbar from "../components/mainComps/navbar";
import Footer from "../components/mainComps/footer";
import SupportMain from "../components/support/main";
import i18next from "i18next";

const Support = () => {

    useEffect(() => {

        document.title = 'Cordes-Hosting | Support';
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <main>
            <Navbar/>
            <SupportMain/>
            <Footer/>
        </main>
    )
}

export default Support;
