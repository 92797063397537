import React from 'react';
import {Player} from '@lottiefiles/react-lottie-player';
import LottieDomains from '../../assets/lottie/domains.json'
import i18next from "i18next";
const domainsStart = () => {
    return(
        <div className='flex md:flex-row-reverse flex-col md:gap-20 gap-0 md:justify-around justify-center pb-4 items-center h-[600px]'>
            <Player
                autoplay
                loop
                src={LottieDomains}
                className='md:w-[420px] max-[1100px]:!w-[200px]'
            />
            <div className='md:text-start text-center pl-4 pr-4 md:pr-0 md:pl-6 flex flex-col md:gap-4 gap-6 md:text-[50px] text-[35px]'>
                <h1 className=''>{i18next.t('domains.domainsStart.toptext_1')} <b className='text-purple-500'>{i18next.t('domains.domainsStart.toptext_2')}</b></h1>
                <h1 className=''>{i18next.t('domains.domainsStart.toptext_3')} <b className='text-purple-500'>{i18next.t('domains.domainsStart.toptext_4')}</b></h1>
            </div>
        </div>
    )
}

export default domainsStart;