import React, {useEffect} from "react";
import { motion, AnimatePresence } from "framer-motion";
import i18next from "i18next";

const CanvasComponent = ({ isVisible, closeCanvas, text }) => {



    return (
        <AnimatePresence>
            {isVisible && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
                    <motion.div
                        initial={{ scale: 0.5, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.5, opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="bg-white p-4 w-[600px] rounded-lg relative z-50 mx-8" // Hinzufügen von "relative" und "z-50" hier
                    >
                        <p className='custom-p-bold md:!text-[17px]'>{text}</p>
                        <button className='custom-button mt-8' onClick={closeCanvas}>{i18next.t('productport.close')}</button>
                    </motion.div>
                    <motion.div
                        onClick={closeCanvas}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.7 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        className="fixed top-0 left-0 w-full h-full bg-black z-10"
                    >
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    );
};

export default CanvasComponent;
