import React from 'react';
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import i18next from 'i18next'; // Importieren Sie i18next für die Übersetzungen

const ResellerSubMain = () => {
    return (
        <div className='md:h-[400px] mt-12 mb-12 md:mt-0 md:mb-0 flex md:flex-row flex-col items-center justify-center md:gap-8 gap-12 transition-all duration-300'>
            <div className='flex font-bold items-center text-center justify-center md:text-[32px] text-[25px] md:w-[80%] tracking-wide '>
                <h1 className='w-full md:w-[80%] text-purple-500 leading-relaxed text-center'>
                    {i18next.t('reselling.submain.title')}
                </h1>
            </div>
            <div className='flex flex-col md:w-[80%] text-center justify-center items-center w-full md:gap-4'>
                <h1 className='text-[21px] pb-7 md:pb-0 max-[534px]:text-[18px] md:text-center font-bold'>
                    {i18next.t('reselling.submain.description')}
                </h1>
                <div className='flex flex-row max-[534px]:flex-col justify-center items-center text-center gap-4'>
                    <motion.a
                        whileHover={{
                            scale: 1.1,
                            transition: 0.03
                        }}
                        whileTap={{
                            scale: 0.9,
                            transition: 0.03
                        }}
                        transition={{ ease: "easeOut", duration: 0.03 }}
                        href='https://outlook.office365.com/owa/calendar/CordesHostingTerminbuchung@cordes-hosting.net/bookings/'
                        target='_blank'
                        className='md:inline-block bg-[#3D73F5] p-2 rounded-3xl hover:bg-[#4169FC] duration-300 text-gray-50 font-bold'>
                        {i18next.t('reselling.submain.bookAppointment')}
                    </motion.a>
                    <Link to='/contact'>
                        <motion.p
                            whileHover={{
                                scale: 1.1,
                                transition: 0.03
                            }}
                            whileTap={{
                                scale: 0.9,
                                transition: 0.03
                            }}
                            transition={{ ease: "easeOut", duration: 0.03 }}
                            href='#'
                            className='md:inline-block bg-[#3D73F5] p-2 rounded-3xl hover:bg-[#4169FC] duration-300 text-gray-50 font-bold'>
                            {i18next.t('reselling.submain.contactUs')}
                        </motion.p>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ResellerSubMain;
