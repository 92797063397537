import React from 'react';
import First from "../../assets/img/resourcePackages/banner_teamspeak_1024x300_2.png";
import {motion} from "framer-motion";
import i18next from "i18next";
import {useTranslation} from "react-i18next";

const ResourceComp = ({title, text, img, link, imgClass}) => {

    const { t } = useTranslation();

    return(
        <div className='flex justify-center items-center w-full px-4'>
            <div className='card-background-resource md:w-[480px] w-full mt-8 mb-8 rounded-3xl shadow-2xl'>
                <div className='w-full md:px-16 px-4 text-left flex flex-col h-full justify-center items-center'>
                    <img src={img} className={imgClass}/>
                    <div className='w-full flex flex-col justify-start gap-4 h-full mt-16'>
                        <h2 className='custom-h2'>{title}</h2>
                        <p className='custom-p-card'>{text}</p>
                        <div className='w-full flex justify-start text-left pt-16'>

                            <motion.a
                                download
                                href={link}
                                whileHover={{ scale: 1.1, transition: 0.03 }}
                                whileTap={{ scale: 0.9, transition: 0.03 }}
                                transition={{ ease: "easeOut", duration: 0.03 }}
                                className='flex bg-[#3D73F5] md:w-[230px] mb-8 text-center w-2/3 p-2.5 pl-5 pr-5 items-center justify-center rounded-3xl hover:bg-[#4169FC] duration-300 text-white font-bold'
                            >
                                {i18next.t('resourcen.general.downloadButton')}
                            </motion.a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResourceComp;