import React, { useState } from 'react';
import language from '../../../configs/languages/de.json';
import i18n from "i18next";
import i18next from "i18next";



function registerDropdownCountry(selectedOption, setSelectedOption, formData, setFormData) {
    const options = Object.entries(i18next.t('register.registerMain.countrys', { returnObjects: true })).map(([key, value]) => ({
        key,
        value
    }));

    const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div className="relative mt-8 w-full">
            <select
                id="nameCountry"
                name='country'
                aria-describedby="filled_success_help"
                value={selectedOption}
                onChange={(e1) => {
                    handleSelectChange(e1); // Erste Aktion
                    handleInputChange(e1); // Zweite Aktion
                }}
                className="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer duration-100"
            >
                <option value="" disabled>
                    {i18next.t('register.registerMain.countrys_dropdown.option')}
                </option>
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.key}
                    </option>
                ))}
            </select>
            <label
                htmlFor="nameCountry"
                className="absolute text-sm text-gray-600 dark:text-gray-200 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
            >
                {i18next.t('register.registerMain.countrys_dropdown.country')}
            </label>
        </div>
    );
}

export default registerDropdownCountry;
