import Main from '../components/mainPage/main'
import Navbar from '../components/mainComps/navbar'
import Hero from '../components/mainPage/hero'
import Products from "../components/mainPage/products";
import Footer from "../components/mainComps/footer";
import {useEffect} from "react";
import i18next from "i18next";


const Home = () => {

    useEffect(() => {

        document.title = i18next.t('pagetitel.home');
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    return (
        <main>
            <Navbar/>
            <Main/>
            <Hero/>
            <Products/>
            <Footer/>
        </main>
    );

}

export default Home;
