import React, {useState, useEffect} from 'react';
// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay} from 'swiper/modules'
import {motion} from "framer-motion";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import '../../index.css'

// import required modules
import {Pagination} from 'swiper/modules';
import i18n from "i18next";
import i18next from "i18next";
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

const Products = () => {
    const {t} = useTranslation();

    const [jsonData, setJsonData] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://api.cordes-hosting.net/website/api/products", requestOptions)
            .then(response => response.json())
            .then(data => {
                setJsonData(data);
                setDataLoaded(true); // Daten wurden geladen
            })
            .catch(error => console.log('error', error));
    }, []);


    return (
        <div className='h-[850px] w-full mx-auto'>
            <div className='pt-28 mx-auto font-bold md:text-[45px] w-full pl-8 pr-8 text-[35px]'>
                <h1 className='flex text-center justify-center items-center mb-12'>{i18next.t('home.products.products_text')}</h1>
            </div>
            {Array.isArray(jsonData) && dataLoaded ? (
                <Swiper
                    pagination={true}
                    loop={true}
                    speed={1200}
                    modules={[Pagination, Autoplay]}
                    slidesPerView={1}
                    breakpoints={{
                        390: {
                            slidesPerView: 1,
                        },
                        576: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        1024: {
                            spaceBetween: 10,
                            slidesPerView: 3,
                        },
                        1280: {
                            slidesPerView: 3,
                        },
                    }}
                    spaceBetween={22}
                    autoplay={{delay: 5000}}
                    className="mySwiper pb-8 !flex w-[80vw] flex-col justify-center items-center !gap-24 rounded-2xl"
                >
                    {jsonData.map((item, index) => (
                        <SwiperSlide key={index} className='flex w-full h-2/3 rounded-2xl'>
                            <div
                                className="relative !w-full p-4 border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700 !bg-opacity-90">
                                {!item.description.offer || item.description.offer === "0" || item.description.offer === null ? null : (
                                    <div
                                        className={`absolute top-2 left-2 text-[28px] rounded-full text-white text-center ${item.description.offer === "%" ? 'md:w-14 md:h-14 w-8 h-8 bg-red-600' : 'md:w-14 md:h-14 w-8 h-8 bg-blue-600'}`}>
                                        <div className='flex w-full h-full items-center justify-center'>
                                            <p>{item.description.offer}</p>
                                        </div>
                                    </div>
                                )}
                                <h5 className="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">{item.label}</h5>
                                <div className="flex items-baseline text-gray-800 dark:text-white">
                                    <span className="text-5xl font-extrabold tracking-tight">{item.price}</span>
                                    <span className="text-3xl font-semibold">€</span>
                                    <span
                                        className="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">/{i18next.t('home.products.month')}</span>
                                </div>
                                <ul role="list" className="space-y-5 my-7">
                                    {i18n.language === 'de-DE' || i18n.language === 'de' ? (
                                        item.description.websiteInfo.map((item2, index2) => (
                                            <li className="flex space-x-3 items-center" key={index2}>
                                                <svg
                                                    className={item2.index === 1 ? "flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" : "flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500"}
                                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                    fill="currentColor" viewBox="0 0 20 20">
                                                    <path
                                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                </svg>
                                                <span
                                                    className={item2.index === 1 ? "text-base font-normal leading-tight text-gray-500 dark:text-gray-400" : "text-base font-normal leading-tight text-gray-500 dark:text-gray-400 line-through"}>
                    {item2.label}
                  </span>
                                            </li>
                                        ))
                                    ) : (
                                        item.description.websiteInfo_en.map((item2, index2) => (
                                            <li className="flex space-x-3 items-center" key={index2}>
                                                <svg
                                                    className={item2.index === 1 ? "flex-shrink-0 w-4 h-4 text-blue-600 dark:text-blue-500" : "flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500"}
                                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                    fill="currentColor" viewBox="0 0 20 20">
                                                    <path
                                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                </svg>
                                                <span
                                                    className={item2.index === 1 ? "text-base font-normal leading-tight text-gray-500 dark:text-gray-400" : "text-base font-normal leading-tight text-gray-500 dark:text-gray-400 line-through"}>
                    {item2.label}
                  </span>
                                            </li>
                                        ))
                                    )}
                                </ul>
                                <Link to='/register'>
                                    <motion.p
                                        whileHover={{
                                            scale: 1.1,
                                            transition: 0.03
                                        }}
                                        whileTap={{
                                            scale: 0.9,
                                            transition: 0.03
                                        }}
                                        transition={{ease: "easeOut", duration: 0.03}}
                                        href='#'
                                        className='cursor-pointer inline-block bg-[#3D73F5] p-1.5 md:pl-12 md:pr-12 pl-6 pr-6 rounded-3xl hover:bg-[#4169FC] duration-300 text-gray-50 font-bold'>
                                        {i18next.t('home.products.buynow')}
                                    </motion.p>
                                </Link>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                <div className="text-center">
                    <div role="status">
                        <svg aria-hidden="true"
                             className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                             viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"/>
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"/>
                        </svg>
                        <span className="sr-only">{i18next.t('home.products.loading')}</span>
                    </div>
                </div>
            )}

        </div>
    )
}

export default Products