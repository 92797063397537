import React, {useEffect} from 'react';
import Navbar from "../components/mainComps/navbar";
import ResellingMain from "../components/reselling/resellingMain";
import ResellerSubMain from "../components/reselling/resellerSubMain";
import ResellerSubMain2 from "../components/reselling/resellerSubMain2";
import Footer from "../components/mainComps/footer";
import ResellingProducts from "../components/reselling/resellingProducts";
import ResellingApi from "../components/reselling/resellingApi";
import ResellerSubMain3 from "../components/reselling/resellerSubMain3";
import i18next from "i18next";

const Reselling = () => {

    useEffect(() => {

        document.title = i18next.t('pagetitel.reselling');
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <main>
            <Navbar/>
            <ResellingMain/>
            <ResellerSubMain/>
            <ResellerSubMain2/>
            <ResellingProducts/>
            <ResellingApi/>
            <ResellerSubMain3/>
            <Footer/>
        </main>
    )
}

export default Reselling;