import React from 'react';
import ResourceComp from "./resourceComp";
import teamspeak from '../../assets/img/resourcePackages/banner_teamspeak_1024x300_2.png'
import teamspeakPack from "../../assets/download/adsCH.zip";
import ads from '../../assets/img/resourcePackages/werbung_gaming_540x540.png'
import adsPack from '../../assets/download/adsCH.zip'
import powered from '../../assets/img/resourcePackages/button_poweredby_400x90_1.png'
import poweredPack from '../../assets/download/powerdByCH.zip'
import mockup from '../../assets/img/resourcePackages/smartphone_mockup_1080x1920.png'
import mockupPack from '../../assets/download/mockupsWallpaperCH.zip'
import icon from '../../assets/img/resourcePackages/logo_black_transparent_10663x4010.png'
import iconPack from '../../assets/download/iconsCH.zip'

import i18n from "i18next";
import i18next from "i18next";
import {useTranslation} from "react-i18next";

const MainResource = () => {

    const { t } = useTranslation();

    return (
        <div className='flex w-full flex-col'>
            <div className='flex w-full justify-center'>
                <h1 className='custom-h1 pt-8 pb-8'>{i18next.t('resourcen.general.logosDesignsIcons.description')}</h1>
            </div>
            <div className='grid lg:grid-cols-3 max-[1105px]:!grid-cols-2 max-[900px]:!grid-cols-1 justify-center items-center'>
            <ResourceComp
                title={i18next.t('resourcen.categories.teamspeakBanner.title')}
                text={i18next.t('resourcen.categories.teamspeakBanner.shortDescription')}
                img={teamspeak}
                link={teamspeakPack}
                imgClass='shadow-2xl rounded-xl mt-20'
            />
            <ResourceComp
                title={i18next.t('resourcen.categories.advertisingPackage.title')}
                text={i18next.t('resourcen.categories.advertisingPackage.shortDescription')}
                img={ads}
                link={adsPack}
                imgClass='shadow-2xl rounded-xl mt-10'
            />
            <ResourceComp
                title={i18next.t('resourcen.categories.poweredBy.title')}
                text={i18next.t('resourcen.categories.poweredBy.shortDescription')}
                img={powered}
                link={poweredPack}
                imgClass='rounded-xl mt-10'
            />
            <ResourceComp
                title={i18next.t('resourcen.categories.mockupsAndWallpapers.title')}
                text={i18next.t('resourcen.categories.mockupsAndWallpapers.shortDescription')}
                img={mockup}
                link={mockupPack}
                imgClass='mt-10'
            />
            <ResourceComp
                title={i18next.t('resourcen.categories.logoPackage.title')}
                text={i18next.t('resourcen.categories.logoPackage.shortDescription')}
                img={icon}
                link={iconPack}
                imgClass='mt-10'
            />
            </div>
        </div>
    )
}

export default MainResource;
