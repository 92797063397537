import React from 'react';
import CodeWindow from "../../functions/apiGadget/react-codeblock";
import i18next from 'i18next'; // Importieren Sie i18next für die Übersetzungen

const ResellingApi = () => {

    const code = `
<?php
include "resApi.php";

$api = new resApi("api_token");

$balance = $api->get("accounting/balance");
echo $balance;
?>
    `

    const code2 = `
<?php
include "resApi.php";

$api = new resApi("api_token");

$cloudServer = $api->post("vserver/create");
echo $cloudServer;
?>
    `

    const code3 = `
<?php
include "resApi.php";

$api = new resApi("api_token");

$installedUpdates = $api->post("vserver/update-management/install");
echo $installedUpdates;
?>
    `

    const code4 = `
<?php
include "resApi.php";

$api = new resApi("api_token");

$domain = $api->post("domain/create");
echo $domain;
?>
    `

    return (
        <div className='flex mx-auto flex-col justify-center items-center pb-12 md:!mx-4 md:!mr-8'>
            <div className='pt-28 mx-auto font-bold md:text-[45px] w-full pl-8 pr-8 text-[35px]'>
                <h1 className='flex text-center justify-center items-center mb-12'>{i18next.t('reselling.api.directOrder')}</h1>
            </div>
            <div className='flex justify-center items-center'>
                <div className='grid md:gap-16 lg:grid-cols-2 md:grid-cols-1 max-[1100px]:w-full '>
                    <div className='w-[90vw] md:w-full md:pb-0 pb-8 flex items-center flex-col justify-center mx-auto'>
                        <h1 className='text-[16px] font-bold text-left pb-4'>{i18next.t('reselling.api.getBalance')}:</h1>
                        <CodeWindow
                            name='first_code'
                            code={code}
                            language='php'
                        />
                    </div>
                    <div className='w-[90vw] md:w-full md:pb-0 pb-8 flex items-center flex-col justify-center mx-auto'>
                        <h1 className='text-[16px] font-bold text-left pb-4'>{i18next.t('reselling.api.createCloudServer')}:</h1>
                        <CodeWindow
                            name='second_code'
                            code={code2}
                            language='php'
                        />
                    </div>
                    <div className='w-[90vw] md:w-full md:pb-0 pb-8 flex items-center flex-col justify-center mx-auto'>
                        <h1 className='text-[16px] font-bold text-left pb-4'>{i18next.t('reselling.api.installUpdates')}:</h1>
                        <CodeWindow
                            name='third_code'
                            code={code3}
                            language='php'
                        />
                    </div>
                    <div className='w-[90vw] md:w-full md:pb-0 pb-8 flex items-center flex-col justify-center mx-auto'>
                        <h1 className='text-[16px] font-bold text-left pb-4'>{i18next.t('reselling.api.orderDomain')}:</h1>
                        <CodeWindow
                            name='fourd_code'
                            code={code4}
                            language='php'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResellingApi;
