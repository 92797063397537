import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";
import '../../index.css'
import {Link} from "react-router-dom";
import i18next from "i18next";

const DomainsSubStart = () => {

    const [jsonData3, setJsonData3] = useState(null);

    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://api.cordes-hosting.net/website/api/domains", requestOptions)
            .then(response => response.json())
            .then(data => {
                setJsonData3(data);
            })
            .catch(error => console.log('error', error));
    }, []);

    return(
        <div className='flex flex-col w-full items-center pb-16'>
            <div className='flex items-center justify-center border-t w-2/3 border-gray-500'></div>
            <div>
                <h1 className='custom-h1 pb-24 pt-8'>{i18next.t('domains.domainsSubStart.whycheap')}</h1>
            </div>
            <div className='w-2/3 flex md:flex-row flex-col gap-20 items-center justify-around'>
                <div className='flex md:w-2/4 md:text-left text-center'>
                    <p className='text-[20px] font-bold'>
                        {i18next.t('domains.domainsSubStart.text_part1')} <b className='text-purple-500'>{i18next.t('domains.domainsSubStart.text_part2')}</b> {i18next.t('domains.domainsSubStart.text_part3')} <b className='text-purple-500'>{i18next.t('domains.domainsSubStart.text_part4')}</b> {i18next.t('domains.domainsSubStart.text_part5')}
                    </p>
                </div>
                <div className='text-[20px] flex font-bold md:w-2/4 gap-8 items-center justify-center text-center flex-col'>
                    <h1>{i18next.t('domains.domainsSubStart.custom_offer')}</h1>
                    <Link to='/contact'>
                        <motion.p
                            whileHover={{
                                scale: 1.1,
                                transition: 0.03
                            }}
                            whileTap={{
                                scale: 0.9,
                                transition: 0.03
                            }}
                            transition={{ease: "easeOut", duration: 0.03}}
                            href='#'
                            className='inline-block bg-[#3D73F5] p-1.5 pl-5 pr-5 rounded-3xl hover:bg-[#4169FC] duration-300 text-white font-bold'>
                            {i18next.t('domains.domainsSubStart.askforoffer')}
                        </motion.p>
                    </Link>
                </div>
            </div>
            <div className='flex items-center md:h-[400px] md:w-full w-3/4 justify-center !rounded-sm pt-24 pb-12'>

                <div className="!rounded-sm shadow-xl cursor-default md:border-0 border border-gray-200 md:overflow-x-hidden overflow-x-scroll">
                    <table className="h-[220px] md:w-[700px] w-[400px] !rounded-sm bg-white">
                        <thead className="bg-[#4169FC] text-white">
                        <tr>
                            <th className="rounded-tl-sm !text-center py-2 px-3 uppercase font-semibold text-xs sm:text-sm">{i18next.t('domains.domainsSubStart.tld')}</th>
                            <th className="!text-center py-2 px-3 uppercase font-semibold text-xs sm:text-sm">{i18next.t('domains.domainsSubStart.newregister')}</th>
                            <th className="!text-center py-2 px-3 uppercase font-semibold text-xs sm:text-sm">{i18next.t('domains.domainsSubStart.transfer')}</th>
                            <th className="!text-center rounded-tr-sm py-2 px-3 uppercase font-semibold text-xs sm:text-sm">{i18next.t('domains.domainsSubStart.keepdomain')}</th>
                        </tr>
                        </thead>
                        <tbody className="text-gray-700 w-full rounded-sm">

                        {jsonData3 && jsonData3.domains.map((domain, index) => (
                            <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                                <td className="!text-center w-[90px] border-r border-gray-300 py-2 px-3 whitespace-nowrap">{domain.name}</td>
                                <td className="!text-center border-r border-gray-300 py-2 px-3 whitespace-nowrap">{domain.create}</td>
                                <td className="!text-center border-r border-gray-300 py-2 px-3 whitespace-nowrap">{domain.transfer}</td>
                                <td className="!text-center py-2 px-3 whitespace-nowrap">{domain.renew}</td>
                            </tr>
                        ))}

                        </tbody>
                    </table>
                </div>

            </div>
            <div className='flex items-center justify-center border-b w-2/3 border-gray-500'></div>

        </div>
    )
}

export default DomainsSubStart;